// ? Loader
.loader {
	background: var(--primary-color);
	height: 100%;
	left: 0px;
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: 9999;	
}
