// ? Footer style
footer{
   padding-top: $section-spacer;
   padding-bottom: 2rem;

   img{
      margin:auto;
      width: 11rem
   }

   h2 {
      line-height: 1.1;
      margin-bottom: 5rem !important;
   }

   .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.8rem;
      margin-top: 1rem;
      margin-bottom: 5rem;

      &::before {
         margin-right: 1rem;
      }
   }

   .bg-secondary,
   .bg-primary {
      padding: 5.5rem 4rem;

      @include lg {
         margin-bottom: 4rem;
      }
   }

   form {
      align-items: center;
      display: flex;
      flex-direction: column;
   }

   .copyright{
      text-align: center;
      margin-bottom: 15px;
      font-size: 1.2rem;

      @include md{
         margin-bottom: 0px;
      }
   }

   .logo-arobas img {
      width: fit-content;
   }
}

