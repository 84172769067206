// ? Button style
button{
   border: none;
   outline: none;
}

[class^=btn-]{
   align-items: center;
   display: inline-flex;
   justify-content: center;
   margin-bottom: $spacer;
   transition: all 0.5s;
   width: fit-content;
   border: solid 3px var(--primary-color);
   text-transform: uppercase;
   font-family: var(--btn-font);
}

.btn-secondary {
   border: 3px solid white;
}

.icon-back-to-top {
   background-color: var(--secondary-color);
   padding: 1.5rem;
   border-radius: 100%;
   width: 4rem;
   height: 4rem;
   position: fixed;
   right: 2.5rem;
   bottom: 2.5rem;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 1;
   transition: 0.3s ease-in-out all;
   opacity: 0;

   @include md {
      right: 5rem;
      bottom: 5rem;
   }

   &.active {
      opacity: 1;
   }

   .icon {
      color: white;
      font-size: 1.3rem;
      transform: rotate(90deg);
      transition: 0.3s ease-in-out color;
   }

   &:hover {
      background-color: white;

      .icon {
         color: var(--secondary-color);
      }
   }
}

// ? Button avec icone
.btn{
   &-icon{
      &:before{ 
         font-size: 2.4rem;
         margin-right: .75rem;
         position: relative;
         vertical-align: middle;
         z-index: 1;
      }
   }
}

