
// ? Mixins

/*
    ?   Typographie
    * ex : @include font-source-primary(1.6rem, $primary-color, 400)
*/
@mixin font-source-primary($size: false, $colour: false, $weight: false,  $lh: false) {
    font-family: var(--primary-font), sans-serif;
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { 
      @if $weight=='Thin' {
        font-weight: 100;
      }

      @if $weight=='ExtraLight' {
        font-weight: 200;
      }

      @if $weight=='Light' {
        font-weight: 300;
      }

      @if $weight=='Regular' {
        font-weight: 400;
      }

      @if $weight=='Medium' {
        font-weight: 500;
      }

      @if $weight=='SemiBold' {
        font-weight: 600;
      }

      @if $weight=='Bold' {
        font-weight: 700;
      }

      @if $weight=='ExtraBold' {
        font-weight: 800;
      }

      @if $weight=='Black' {
        font-weight: 800;
      }

      @else{
        font-weight: $weight;
      }
    }
    @if $lh { line-height: $lh; }
}

@mixin font-source-secondary($size: false, $colour: false, $weight: false,  $lh: false) {
    font-family: var(--secondary-font), sans-serif;
    @if $size { font-size: $size; }
    @if $colour { color: $colour; }
    @if $weight { 
      @if $weight=='Thin' {
        font-weight: 100;
      }

      @if $weight=='ExtraLight' {
        font-weight: 200;
      }

      @if $weight=='Light' {
        font-weight: 300;
      }

      @if $weight=='Regular' {
        font-weight: 400;
      }

      @if $weight=='Medium' {
        font-weight: 500;
      }

      @if $weight=='SemiBold' {
        font-weight: 600;
      }

      @if $weight=='Bold' {
        font-weight: 700;
      }

      @if $weight=='ExtraBold' {
        font-weight: 800;
      }

      @if $weight=='Black' {
        font-weight: 800;
      }

      @else{
        font-weight: $weight;
      }
    }
    @if $lh { line-height: $lh; }
}

/*
    ?   Position absolute
    * ex :     @include abs-position(25% , 35%, auto, auto);
*/
@mixin abs-position ($top, $right, $bottom, $left) {
    position: absolute; 
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

/*
    ?   Flex
    * ex :     @include flex-center;
*/
@mixin flex-column {
  display: flex;
  flex-direction: column;
}
  
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-column {
  @include flex-center;
  flex-direction: column;
}

@mixin flex-center-vert {
  display: flex;
  align-items: center;
}
  
@mixin flex-center-horiz {
  display: flex;
  justify-content: center;
}

/*
    ?   Background Image en Cover
    * ex :     @include cover-background;
*/
@mixin cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-img{
  @include cover-background;
}

/*
    ?   Aspect ratio on the parent
    * ex :     @include aspect-ratio(16,9);

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
*/

/*
    ?  a mixin to add grid-gap to flexbox
    * ex :     @include flexGap( 10px, row, ".aClass")
*/
@mixin flexGap( $gap, $direction, $element ){
  > #{$element} {
      @if $direction == row {
        margin: 0 $gap/2;
    
      &:first-child{
          margin-left: 0;
      }

      &:last-child{  
        margin-right: 0;
      }
    }

    @if $direction == column{
      margin: $gap/2 0;

      &:first-child { 
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

/*
    ?  a mixin to pseudo element (before or after)
    * ex :     @include pseudo( 10px, row, ".aClass")
*/

@mixin pseudo($loc: before, $content: '', $pos: absolute, $top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: relative;

  &:#{$loc}{
    content: $content;
    position: $pos;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }
}


/*
    ?  media queries
    * ex :     @include md;
*/
@mixin sm {
  @media (min-width:var(--sm)) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) and (orientation: landscape) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 992px) and (orientation: landscape) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1400px) {
    @content;
  }
}

@mixin xxxl {
  @media (min-width: 1700px) {
    @content;
  }
}

@mixin mq($width, $minmax:min) {
  @media (#{$minmax}-width: $width) {
    @content;
  }
}
