// ? Navigation Desktop
// ! En mobile, le menu desktop et le bouton du header n'apparaisse pas
#menu-dekstop, .btn-header{
   display: none;
}

@include lg {
   .btn-header{
      display: inherit;
   }

   #menu-dekstop{
      align-items: center;
      display: flex;
      width: 100%;
      justify-content: flex-end;

      nav{
         ul{
            display: flex;
            
            li{
               padding: 0 1.2rem;
               
               a{
                  font-size: 1.4rem;
                  padding-bottom: 1px;
                  position: relative;                 
               }
               
               // * Hover animation in desktop menu
               a:hover{
                  color: var(--primary-color);
               }
               
               a::after {
                  content: '';
                  background: var(--primary-color);
                  bottom: 0;
                  height: 2px;               
                  left: 0;          
                  opacity: 0;
                  position: absolute;
                  transform: translateY(3px);
                  transition: opacity 0.2s ease, transform 0.2s ease;
                  width: 100%;
               }
               
               a:hover::after {
                  opacity: 1;
                  transform: translateY(0);
               }
            }
         }
      }
   }
}
