
// ? Formulaires
form{
   margin-top: $input-margin*2;

   label {
      font-size: 1.5rem;
      font-weight: 700;
      font-family: var(--primary-font);
      margin-top: $input-margin;
      letter-spacing: 1px;
   }

   input{
      border:solid 1px $input-border-color;
      margin: $input-margin 0rem;
      padding: $input-padding;
      width: 100%;
      border-radius: 5px;
      
      &:focus{
         outline: none;
      }

      &::placeholder {
         font-size: 1.5rem;
         font-weight: 400;
         font-family: var(--primary-font);
      }
   }

   textarea{
      border:solid 1px $input-border-color;
      height: 27rem !important;
      margin: $input-margin 0rem;
      padding: $input-padding;
      width: 100%;
      border-radius: 5px;

      &:focus{
         outline: none;
      }

      &::placeholder {
         font-size: 1.5rem;
         font-weight: 400;
         font-family: var(--primary-font);
      }
   }

   .nice-select{
      border:solid 1px $input-border-color;
      margin: $input-margin 0rem;
      padding: $input-padding;

      .current{
         color: grey;
         opacity: 0.6;
      }
   }

   .btn-upload{
      @extend input;
      align-items: center;
      color: grey;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      input[type="file"] {
         display: none;
      }

      i{
         height: 100%;
         width: 3rem;

         &::before{ 
            display: flex;
            color: var(--primary-color);
            font-size: 2rem;
         }
      }
      
      
   }

   button{
      margin-top: $input-margin*2;
   }  
}

// ? Alert envoi réussi
.alert--success{
   @include font-source-primary(2rem, white, 400, 25px);
   background-color: #28a745!important;
   padding: 1rem;
}

// ? Custom Checkbox
/* Hide the browser's default checkbox */
.check-container {
   margin: 0.8rem 3.5rem 0.8rem 3.5rem;
   position: relative;
   font-size: 1.5rem;
   font-weight: 400;
   text-transform: initial;

   input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
   }
}

 /* Create a custom checkbox */
.checkmark {
   background-color: #eee;
   border: black solid 1px;
   border-radius: 5px;
   position: absolute;
   top: 0;
   left: -3.5rem;
   height: 25px;
   width: 25px;
}

 /* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
   background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
background-color: var(--primary-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
content: "";
position: absolute;
display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
left: 9px;
top: 5px;
width: 5px;
height: 10px;
border: solid white;
border-width: 0 3px 3px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
} 


// ? Custom checkbox
.radio-container{
   width: 100%;
   text-align: left;
   .radio-button{
      @include font-source-primary(2rem, map-get($colors, primary), 700);
      justify-content: flex-start;
      margin: 1.5rem 0;
      width: 100%;
      
      @include md {
         width: 50%;
      }
   }
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
   input[type='radio'] {
      --active: #213366;
      --active-inner: #fff;
      --focus: 2px rgba(33, 51, 102, .3);
      --border: #BBC1E1;
      --border-hover: #213366;
      --background: #fff;
      --disabled: #F6F8FF;
      --disabled-inner: #E1E6F9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: 0;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background .3s, border-color .3s, box-shadow .2s;
      &:after {
         content: '';
         display: block;
         left: 0;
         top: 0;
         position: absolute;
         transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
      }
      &:checked {
         --b: var(--active);
         --bc: var(--active);
         --d-o: .3s;
         --d-t: .6s;
         --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
      }
      &:disabled {
         --b: var(--disabled);
         cursor: not-allowed;
         opacity: .9;
         &:checked {
            --b: var(--disabled-inner);
            --bc: var(--border);
         }
         & + label {
            cursor: not-allowed;
         }
      }
      &:hover {
         &:not(:checked) {
            &:not(:disabled) {
            --bc: var(--border-hover);
            }
         }
      }
      &:focus {
         box-shadow: 0 0 0 var(--focus);
      }
      &:not(.switch) {
         width: 21px;

         &:checked {
         --o: 1;
         }
      }
      & + label {
         font-size: 14px;
         line-height: 21px;
         display: inline-block;
         vertical-align: top;
         cursor: pointer;
         margin-left: 4px;
      }
   }

   input[type='radio'] {
      border-radius: 50%;
      
      &:after {
         width: 19px;
         height: 19px;
         border-radius: 50%;
         background: var(--active-inner);
         opacity: 0;
         transform: scale(var(--s, .7));
      }
      &:checked {
         --s: .5;
      }
   }
}