//? Fonts
//? Primary Headings 
@each $tag, $size, $weight, $color in $primary-typo {
    #{$tag},
    .#{$tag}{
        font-size: $size;
        font-weight: $weight;
        color: $color;
    }
}

//? Other fonts
@each $tag, $size, $weight, $color in $other-typo {
    #{$tag}{
        font-size: $size;
        font-weight: $weight;
        color: $color;
    }
}

// ? Text color and background-color
@each $name, $color in $colors{
    .text-#{$name}{
        color: $color;

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, a{
            color: $color;
        }
    }

    .bg-#{$name}{
        background-color: #{$color};
    }
}

// ? Buttons
@each $name, $bg-color, $text-color, $bg-hover, $text-hover, $border-radius, $padding in $buttons{
    .btn-#{$name}{
        background-color: #{$bg-color};
        color: #{$text-color};
        border-radius: #{$border-radius};
        padding: #{$padding};
        transition: all 0.3s ease-in-out;
        min-width: fit-content;
        text-align: center;

        &:hover{
            background-color: #{$bg-hover};
            color: #{$text-hover}; 
        }
    }
}

// ? Backgrounds
.bg-transparent {
    background-color: transparent;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-cover {
    background-size: cover;
}

.bg-contain {
    background-size: contain;
}


// ? Positions

.static {
    position: static;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.inset-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.top-0 {
    top: 0px;
}

.right-0 {
    right: 0px;
}

.bottom-0 {
    bottom: 0px;
}

.left-0 {
    left: 0px;
}

@each $breakpoint, $width in $breakpoints{
    @media (min-width:#{$width}) {
        .static-#{$breakpoint} {
            position: static;
        }
        
        .fixed-#{$breakpoint} {
            position: fixed;
        }
        
        .absolute-#{$breakpoint} {
            position: absolute;
        }
        
        .relative-#{$breakpoint} {
            position: relative;
        }
        
        .sticky-#{$breakpoint} {
            position: -webkit-sticky;
            position: sticky;
        }
        
        .inset-#{$breakpoint}-0 {
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }
        
        .top-#{$breakpoint}-0 {
            top: 0px;
        }
        
        .right-#{$breakpoint}-0 {
            right: 0px;
        }
        
        .bottom-#{$breakpoint}-0 {
            bottom: 0px;
        }
        
        .left-#{$breakpoint}-0 {
            left: 0px;
        }
    }
}

// ? Display
.d-grid {
    display: grid;
}

.d-inline-grid {
    display: inline-grid;
}

@each $breakpoint, $width in $breakpoints{
    @media (min-width:#{$width}) {
        .d-#{$breakpoint}-grid {
            display: grid;
        }
        
        .d-#{$breakpoint}-inline-grid {
            display: inline-grid;
        }
    }
}


// ? Font Weihgts

.font-thin {
    font-weight: 100;
}

.font-extralight {
    font-weight: 200;
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.font-extrabold {
    font-weight: 800;
}

.font-black {
    font-weight: 900;
}

// ? Opacity

.opacity-0 {
    opacity: 0;
}

.opacity-5 {
    opacity: 0.05;
}

.opacity-10 {
    opacity: 0.1;
}

.opacity-20 {
    opacity: 0.2;
}

.opacity-25 {
    opacity: 0.25;
}

.opacity-30 {
    opacity: 0.3;
}

.opacity-40 {
    opacity: 0.4;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-60 {
    opacity: 0.6;
}

.opacity-70 {
    opacity: 0.7;
}

.opacity-75 {
    opacity: 0.75;
}

.opacity-80 {
    opacity: 0.8;
}

.opacity-90 {
    opacity: 0.9;
}

.opacity-95 {
    opacity: 0.95;
}

.opacity-100 {
    opacity: 1;
}

// ? Overflow

.overflow-hidden {
    overflow: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

// ? Text transform
.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.capitalize {
    text-transform: capitalize;
}

// ? Text align

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@each $breakpoint, $width in $breakpoints{
    @media (min-width:#{$width}) {
        .text-#{$breakpoint}-left {
            text-align: left !important;
        }
        
        .text-#{$breakpoint}-right {
            text-align: right !important;
        }
        
        .text-#{$breakpoint}-center {
            text-align: center !important;
        }
    }
}

// ? Z-index

.z-0 {
    z-index: 0;
}

.z-10 {
    z-index: 10;
}

.z-20 {
    z-index: 20;
}

.z-30 {
    z-index: 30;
}

.z-40 {
    z-index: 40;
}

.z-50 {
    z-index: 50;
}

// ? Padding & Margins 

// ? not inside media query
$numbers: 0, 10, 15, 20, 30,;

@each $number in $numbers{
    .p-#{$number}{
        padding: #{$number}px;
    }
    
    .pt-#{$number}{
        padding-top: #{$number}px;
    }
    
    .pb-#{$number}{
        padding-bottom: #{$number}px;
    }
    
    .pl-#{$number}{
        padding-left: #{$number}px;
    }
    
    .pr-#{$number}{
        padding-right: #{$number}px;
    }
    
    .px-#{$number}{
        padding-right: #{$number}px;
        padding-left: #{$number}px;
    }
    
    .py-#{$number}{
        padding-top: #{$number}px;
        padding-bottom: #{$number}px;
    }

    .m-#{$number}{
        margin: #{$number}px;
    }
    
    .mt-#{$number}{
        margin-top: #{$number}px;
    }
    
    .mb-#{$number}{
        margin-bottom: #{$number}px;
    }
    
    .ml-#{$number}{
        margin-left: #{$number}px;
    }
    
    .mr-#{$number}{
        margin-right: #{$number}px;
    }
    
    .mx-#{$number}{
        margin-right: #{$number}px;
        margin-left: #{$number}px;
    }
    
    .my-#{$number}{
        margin-top: #{$number}px;
        margin-bottom: #{$number}px;
    }
}

$spacing :
576px  'sm'  0 10 15 20 30,
768px  'md'  0 10 15 20 30,
992px  'lg'  0 10 15 20 30,
1200px 'xl'  0 10 15 20 30,
1400px 'xxl' 0 10 15 20 30,
1700px 'xxl' 0 10 15 20 30,;

@each $value in $spacing {
    @for $i from 3 through 7{
        @media (min-width:#{nth($value, 1)}) {
            .p-#{nth($value, 2)}-#{nth($value, $i)}{
                padding: #{nth($value, $i)}px;
            }
            
            .pt-#{nth($value, 2)}-#{nth($value, $i)}{
                padding-top: #{nth($value, $i)}px;
            }
            
            .pb-#{nth($value, 2)}-#{nth($value, $i)}{
                padding-bottom: #{nth($value, $i)}px;
            }
            
            .pl-#{nth($value, 2)}-#{nth($value, $i)}{
                padding-left: #{nth($value, $i)}px;
            }
            
            .pr-#{nth($value, 2)}-#{nth($value, $i)}{
                padding-right: #{nth($value, $i)}px;
            }
            
            .px-#{nth($value, 2)}-#{nth($value, $i)}{
                padding-right: #{nth($value, $i)}px;
                padding-left: #{nth($value, $i)}px;
            }
            
            .py-#{nth($value, 2)}-#{nth($value, $i)}{
                padding-top: #{nth($value, $i)}px;
                padding-bottom: #{nth($value, $i)}px;
            }

            .m-#{nth($value, 2)}-#{nth($value, $i)}{
                margin: #{nth($value, $i)}px;
            }
            
            .mt-#{nth($value, 2)}-#{nth($value, $i)}{
                margin-top: #{nth($value, $i)}px;
            }
            
            .mb-#{nth($value, 2)}-#{nth($value, $i)}{
                margin-bottom: #{nth($value, $i)}px;
            }
            
            .ml-#{nth($value, 2)}-#{nth($value, $i)}{
                margin-left: #{nth($value, $i)}px;
            }
            
            .mr-#{nth($value, 2)}-#{nth($value, $i)}{
                margin-right: #{nth($value, $i)}px;
            }
            
            .mx-#{nth($value, 2)}-#{nth($value, $i)}{
                margin-right: #{nth($value, $i)}px;
                margin-left: #{nth($value, $i)}px;
            }
            
            .my-#{nth($value, 2)}-#{nth($value, $i)}{
                margin-top: #{nth($value, $i)}px;
                margin-bottom: #{nth($value, $i)}px;
            }
        }
    }
}