// ? General
.row {
    margin-right:0;
    margin-left: 0;
}

img{
    display: block;
    height: auto;
    width: 100%;
}

// ? Links
a {
    text-decoration: none;
    
    &:hover{
        text-decoration: none;
    }
}