// ? Header
nav a, .pushy-content li{
   @include font-source-primary(1.5rem, black, 600, 1);
}

header{
   background-color: transparent;
   padding: $spacer;
   position: fixed;
   top: 0;
   width: 100%;
   z-index: 10;
   transition: background-color 0.3s ease-in-out;

   #logo {
      max-width: 35rem;
   }

   .btn{
      margin-bottom: 0;
   }

   @include lg {
      justify-content: center;
   }

   &.stickyHeader {
      background-color: white;
   }
}