// ? Slider Thumbnail
.swiper-slide {
   display: grid;
   height: auto;

   &>div {
      padding: 3rem;
      background-color: white;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
   }

   .icon {
      color: var(--secondary-color);
      font-size: 3.1rem;
      margin-bottom: 4rem;
   }

   p {
      margin-top: 3rem;
   }

   h3 {
      border-top: 1px solid #E6E7E9;
      padding-top: 1.5rem;
      margin-top: 1.5rem;

      @include lg {
         padding-top: 3rem;
         margin-top: 3rem;
      }
   }
}

.swiper-button-prev, 
.swiper-button-next {
   font-size: 22rem;
   
   svg {
      fill: white;
   }

   &::after {
      content: "";
   }
}

.swiper-button-prev {
   left: -5%;
}

.swiper-button-next {
   right: -5%;
   transform: rotate(180deg);
}