// ? Hero style
.hero-container {
    // * Desktop
    @include lg {
        min-height: 50rem;
    }

    @include xxl {
        height: 100vh;
    }

    .hero-cta {
        background-color: white;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 10rem;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2.5rem;
        }

        .icon {
            font-size: 2.5rem;
            color: var(--primary-color);
            transition: ease-in-out 0.3s color;
            animation: bounce 2s infinite;   

            &:hover {
                color: var(--secondary-color);
            }

            &::before {
                font-size: 3rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-90deg);
            }
        
            @keyframes bounce {
                0%, 20%, 50%, 80%, 100% {
                    transform: translateY(0);
                }
                40% {
                    transform: translateY(-30px);
                }
                60% {
                    transform: translateY(-15px);
                }
            }
        }
    }
}

    .hero{
    // * Mobile
    overflow: hidden;
    background-color: rgba(var(--rgb-primary-color), 0.16);
    padding-top: 15rem;
    position: relative;
    /*
    background-position: right;
    background-size: 90vh;
    background-repeat: no-repeat;
    style="background-image: url('assets/img/RDTTAQ-illustrations.svg');"
    */

    img {
        width: 100%;

        @include xl {
            width: fit-content;
        }
    }
    // * Tablette
    @include md{
        padding-top: 15rem;
    }

    @include xl{
        padding-top: 10rem;
    }

    @include lg {
        height: calc(100vh - 10rem);
    }

    .btn-primary {
        margin-top: 5rem;
    }
}