// ? Projet Grid
.fluid-container{
   .card-row{
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: 524px;
      
      // * mobile
      @media screen and (max-width: 480px){
         grid-auto-rows: 262px;
      }
      
      .card[style^="background-image:"][style*="greiche-and-scaff_dollar-max.jpg"] {
         background-position: 0;
      }
      
      .card{
         @include cover-background;
         align-items: center;
         display: flex;
         justify-content: center;
         position: relative;
   
         .overlay{
            background-color:  var(--primary-color);
            height: 85%;
            opacity: 0;
            transition: .7s;
            width: 85%;
         }
   
         .card-content{
            margin: auto;
            opacity: 0;
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            transition: .7s;            
            width: 80%;       
         }
   
         &:hover {
            .overlay{
               opacity: .7;   
            }
   
            .card-content{
               opacity: 1;
            }
         }
      }
   }

// ? Tablette et Desktop
   @media screen and (min-width: 1025px){
      .card-row{
         display: grid;
         grid-template-columns: 33% 33% 33%;
         grid-auto-rows: 524px;

         &:nth-child(odd){
            .card:nth-child(odd){
               grid-column: 1/3;
            }
   
            .card:nth-child(even){
               grid-column: 3/4;
            }
         }
   
         &:nth-child(even){
            .card:nth-child(odd){
               grid-column: 1/2;
            }
            .card:nth-child(even){
               grid-column: 2/4;
            }
         }
      }
   }
}