// ? Breakpoints
$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl:1400px,
  xxxl:1700px,
);

// ? Couleurs
$colors: (
  primary: #2053af,
  secondary: #ec2115,
  black: #000000,
  white: #ffffff,
);

// ? Fonts
$fonts:(
  primary: Montserrat,
  secondary: Montserrat,
  btn: Montserrat,
);

// ? Typo générales
//* $tag, $size, $weight, $color 
$primary-typo: 
(h1          3rem       700     black),
(h2          3rem       700     black),
(h3          2rem       700     map-get($colors, primary)),
(h4          2rem       700     black),
(p           1.8rem     400     black);

// ? Typo autres
//* $tag, $size, $weight, $color 
$other-typo: 
('p.small'                                     1.7rem     400      black),
('[class^=btn-]'                               1.5rem     700      white),
('input, textarea'                             1.8rem     600      black),
('input:placeholder, textarea:placeholder'     1.8rem     600      black);

// ? Boutons
//*  $name, $bg-color, $text-color, $bg-hover, $text-hover, $border-radius, $padding
$buttons:
(primary          var(--primary-color)      white                     transparent                  var(--primary-color)       5px     (1.1rem 1.6rem)),
(secondary      white                     var(--primary-color)        var(--primary-color)       white                      5px     (1.1rem 1.6rem)),;

// ? Spacers
$section-spacer: 6rem;
$spacer: 1.5rem;

// ? Inputs
$input-border-color: #DEE5EC;
$input-padding: 1.6rem;
$input-margin: 0.8rem;


:root{
  @each $name, $color in $colors{
    --#{$name}-color: #{$color};
  }

  --rgb-primary-color : 32, 83, 175;

  @each $name, $font in $fonts{
    --#{$name}-font: #{$font};
  }

  @each $breakpoint, $width in $breakpoints{
    --#{$breakpoint}: #{$width};
  }
}
