@font-face {
	font-family: "icons";
	src: url('../fonts/icons.eot');
	src: url('../fonts/icons.eot?#iefix') format('eot'),
		url('../fonts/icons.woff2') format('woff2'),
		url('../fonts/icons.woff') format('woff'),
		url('../fonts/icons.ttf') format('truetype'),
		url('../fonts/icons.svg#icons') format('svg');
}

.icon:before {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-ic_aucune-obligation:before {
	content: "\E001";
}

.icon-ic_evaluation-telephonique:before {
	content: "\E002";
}

.icon-ic_fleche:before {
	content: "\E003";
}

.icon-ic_modalite-de-paiement:before {
	content: "\E004";
}

.icon-ic_ouverture-dossier:before {
	content: "\E005";
}

.icon-ic_quote:before {
	content: "\E006";
}

.icon-ic_revision-administrative:before {
	content: "\E007";
}

.icon-ic_service-a-domicile:before {
	content: "\E008";
}

.icon-ic_telephone:before {
	content: "\E009";
}
