// ? Font Style

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

// ? Général

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body{
  color: black;
}

.icon{
  line-height: 1;
}

// ? Titles

h1, .h1, 
h2, .h2, 
h4, .h4, 
h5, .h5, 
h6, .h6, 
p {

  &:not(:last-child){
    margin-bottom:$spacer;
  }
}

h1,
.h1,
h2,
.h2,
h3, 
.h3,
h4, 
.h4, 
h5, 
.h5, 
h6, 
.h6 {
  font-family: var(--primary-font);
}

@include xxl {
  h1, .h1 {
    font-size: 4rem;
  }
}

h3, .h3 {
  letter-spacing: 2px;
}

// ? Paragraphs

p {
  font-family: var(--secondary-font);
}

nav li a{
  font-size: 1.5rem; 
  text-transform: uppercase;  
}

.pushy-content li a{
  font-size: 2rem;   
}