@import "base/reset";
@import "bootstrap/bootstrap-custom.scss";

// ? IMPORTATION DES STYLESHEETS DU DOSSIER UTILITIES
@import "utilities/variables";
@import "utilities/mixins";
@import "utilities/classes";
@import "utilities/misc";

// ? IMPORTATION DES STYLESHEETS DU DOSSIER VENDORS
@import "vendors/pushy";
@import "vendors/animate.min";
@import "vendors/swiper-bundle";

// ? IMPORTATION DES STYLESHEETS DU DOSSIER BASE
@import "base/fonts";
@import "base/icons";

// ? IMPORTATION DES STYLESHEETS DU DOSSIER MODULES
@import "components/buttons";
@import "components/card";
@import "components/temoignages";
@import "components/loader";
@import "components/accordion";
@import "components/project";
@import "components/list";

// ? IMPORTATION DES STYLESHEETS DU DOSSIER MODULES
@import "block/slider";
@import "block/banner";
@import "block/hero";
@import "block/section";

// ? IMPORTATION DES STYLESHEETS DU DOSSIER LAYOUT
@import "layout/header";
@import "layout/hamburger";
@import "layout/navigation";
@import "layout/pushy";
@import "layout/footer";
@import "layout/forms";
