// ? Card style
.card-service {
    border: white solid 3px;
    padding: 3rem 1.5rem;
    border-radius: 5px;
    width: 100%;
    margin: 15px 0;

    @include lg{
        width: calc(50% - 30px);
        margin: 15px;
        padding: 5rem 3.5rem;
    }

    h3 {
        width: 90%;
        margin-bottom: 3rem;
        
        @include xxl{
            height: 25%;
        }
    }
}

.card-service--cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 9.5rem 3.5rem;
    flex: 1;

    h3 {
        color: white;
        margin-bottom: 2rem;
        height: fit-content;
    }
}

.card-icon {
    text-align: center;
    box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    margin: 1.5rem 1.5rem;
    border-radius: 5px;

    h3 {
        letter-spacing: 1;
    }

    @include md {
        width: calc(50% - 30px);
    }

    @include lg {
        width: calc(33% - 30px);
    }
    
    .icon {
        color: var(--secondary-color);
        font-size: 6rem;
        margin-bottom: 2.5rem;
        display: block;
    }
}